@import "index";
@import "./bootstrap/bootstrap";

html,
body {
  width: 100%;
  height: 100%;
  font-size: 16px;
  background-color: #EDE4D4;
  @include media-breakpoint-down(sm) {
    font-size: 14px;
  }
}

.container {
  &-inner {
    padding: {
      top: #{$navigator-height};
    }

    @include media-breakpoint-down(sm) {
      padding: {
        top: #{$navigator-height-sm};
      }
    }
  }
}

.list {
  &-image {
    max-width: 200px;
    max-height: 120px;

    img {
      width: auto;
      height: 120px;

      @include media-breakpoint-down(sm) {
        width: 100%;
        height: auto;
      }
    }
  }

  &-title {
    a {
      color: inherit;
    }
  }
}

input:focus,
textarea:focus,
select:focus,
*:focus,
button:focus {
  outline: none !important;
}

.field {
  display: flex;
  flex-flow: column-reverse;

  label,
  input {
    transition: all 0.2s;
    touch-action: manipulation;
  }

  input {
    font-size: 17px;
    border: 0;
    font-family: inherit;
    -webkit-appearance: none;
    border-radius: 5px;
    padding: 25px 25px 10px;
    cursor: text;
    background-color: #f2f3f5;
  }

  input:focus {
    outline: 0;
  }

  label {
    font-size: 12px;
  }

  input:placeholder-shown + label {
    cursor: text;
    max-width: 66.66%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(0, 2.125rem) scale(1.5);
    position: relative;
    top: 17px;
    left: 25px;
    font-size: 12px;
    color: gray;
  }

  ::-webkit-input-placeholder {
    opacity: 0;
    transition: inherit;
  }

  input:focus::-webkit-input-placeholder {
    opacity: 1;
  }

  input:not(:placeholder-shown) + label,
  input:focus + label {
    transform: translate(0, 0) scale(1);
    cursor: pointer;
    font-size: 12px;
    position: relative;
    top: 32px;
    left: 25px;
  }
}

.subMenu {
  position: relative;
  z-index: 1;
  background: #ffffff;
  display: inline-block;
  margin-bottom: 30px;
  border-radius: 5px;
  a {
    padding: 10px 50px;
    display: inline-block;
    color: #28292a;
    &.active {
      color: #1390dd;
      background-image: var(--icon-dropdown-blue);
      background-size: 10px;
      background-repeat: no-repeat;
      background-position: 10% 17px;
    }
  }
}

.follow-us {
  position: fixed;
  z-index: 10;
  right: 0;
  top: 40%;
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      text-align: center;
      a {
        width: 50px;
        height: 50px;
        background-color: #28292a;
        float: left;
        margin-bottom: 2px;
        &:hover {
          background-color: #000000;
        }
      }
      img {
        margin: 15px auto;
      }
      .facebook {
        width: 10px;
      }
      .line {
        width: 23px;
      }
      .youtube {
        width: 22px;
      }
    }
  }
}

//
// Bootstrap overrides and helpers
//

//
// Dropdown
//
.dropdown-toggle {
  &::after {
    display: none !important;
  }
}

.dropdown-submenu {
  position: relative;

  .dropdown-menu {
    width: 100%;
    top: 0;

    &.dropdown-menu-right {
      left: 0;
      right: auto;
    }

    &.show {
      &.dropdown-menu-right {
        transform: translateX(-100%);
      }
    }

    .dropdown-item {
      white-space: initial;
    }
  }
}

// App global styles
.fresnel-container {
  all: unset;
}

.scrollbar {
  &-invisible {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

iframe {
  z-index: -1 !important;
}
