#sendUsMessage {
    background-color: #EDE4D4;
    display: flex;
    flex-direction: row;
    justify-content: center ;
    width: 80%;
    margin: 0px auto;
    padding: 50px 0px;
    .messageContainer {
        margin-right: 30px;
        width: 40%;
        .headerMessage {
            display: flex;
            flex-direction: row;
            margin-bottom: 20px;
            .emailIcon {
                margin-right: 20px;
            }
            h2 {
                font-family: 'Assistant';
                font-weight: 700;
                color: #AE997F;
                margin: 0px;
                align-self: center;
                font-size: 28px;
            }
        }

        p {
            font-family: 'Assistant';
            font-weight: 600;
            font-size: 20px;
            color: #AE997F;
        }
    }
    .input-container {
  position: relative;
  margin-bottom: 15px;
}

.input-container input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.input-container label {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  transition: 0.3s ease all;
  color: #aaa;
}

.input-container.focused label,
.input-container input:valid + label {
  font-size: 12px;
  top: 0;
  color: #555;
}
    .inputContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60%;
        .inputList {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 10px;
            input {
                padding: 15px;
                border: 2px solid #AE997F80;
                background-color: #EDE4D4;
                color: #AE997F;
                border-radius: 10px;
                width: 49%;
            }
            input::placeholder  {
                color: #AE997F;
            }
        }
        .textareaList {
            width: 100%;
            margin-bottom: 40px;
            textarea {
                width: 100%;
                height: 200px;
                background-color: #EDE4D4;
                color: #AE997F;
                border-radius: 10px;
                padding: 12px 20px;
                box-sizing: border-box;
                border: 2px solid #AE997F80;
                font-size: 16px;
                resize: none;
            }
            textarea::placeholder  {
                color: #AE997F;
            }
        }
    }
    .sendMessageBtn {
        font-family: 'Assistant';
        font-weight: 700;
        font-size: 20px;
        width: 45%;
        background-color: #866339;
        border: 1px solid #866339;
        border-radius: 20px;
        padding: 8px 16px;
        color: #CEBAA4;
    }
    .dropdown {
        position: relative;
        cursor: default;
        width: 49%;
        padding: 0;
        text-align: left;
        color: #AE997F;    
      }
      
      .dropdown .arrow {
        border-color: #866339 transparent transparent;
        border-style: solid;
        border-width: 5px 5px 0;
        content: " ";
        display: block;
        height: 0;
        margin-top: 0.3rem;
        position: absolute;
        right: 10px;
        top: 23px;
        width: 0;
      }
      
      .dropdown .arrow.open {
        border-color: transparent transparent #866339;
        border-width: 0 5px 5px;
      }
      
      .dropdown .selected-value input {
        line-height: 1.5;
        font-size: 1rem;
        padding: 15px;
        border: 2px solid #AE997F80;
        background-color: #EDE4D4;
        color: #AE997F;
        border-radius: 10px;
        box-sizing: border-box;
        cursor: default;
        outline: none;
        width: 100%;
        cursor: pointer;
      }
      
      .dropdown .options {
        display: none;
        color: #AE997F;    
        background-color: #EDE4D4;
        border: 1px solid #ccc;
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
        box-sizing: border-box;
        margin-top: -1px;
        max-height: 200px;
        overflow-y: auto;
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 1000;
        -webkit-overflow-scrolling: touch;
      }
      
      .dropdown .options.open {
        display: block;
        border-radius: 10px;
      }
      
      .dropdown .option {
        box-sizing: border-box;
        color: #AE997F;    
        cursor: pointer;
        display: block;
        padding: 8px 10px;
        border: 0.5px solid #AE997F80;
      }
      
      .dropdown .option.selected,
      .dropdown .option:hover {
        background-color: #E5D7C7;
        color: #AE997F;
      }
      
}
#sendUsMessageMobile {
    background-color: #EDE4D4;
    display: flex;
    flex-direction: column;
    justify-content: center ;
    width: 90%;
    margin: 0px auto;
    padding: 40px 0px;
    .messageContainer {
        margin-right: 30px;
        width: 100%;
        .headerMessage {
            display: flex;
            flex-direction: row;
            margin-bottom: 20px;
            .emailIcon {
                margin-right: 20px;
            }
            h2 {
                font-family: 'Assistant';
                font-weight: 700;
                color: #AE997F;
                margin: 0px;
                align-self: center;
                font-size: 28px;
            }
        }

        p {
            font-family: 'Assistant';
            font-weight: 600;
            font-size: 20px;
            color: #AE997F;
        }
    }
    .inputContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .inputList {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 10px;
            input {
                padding: 15px;
                border: 2px solid #AE997F80;
                background-color: #EDE4D4;
                color: #AE997F;
                border-radius: 10px;
                width: 49%;
            }
            input::placeholder  {
                color: #AE997F;
            }
        }
        .textareaList {
            width: 100%;
            margin-bottom: 40px;
            textarea {
                width: 100%;
                height: 200px;
                background-color: #EDE4D4;
                color: #AE997F;
                border-radius: 10px;
                padding: 12px 20px;
                box-sizing: border-box;
                border: 2px solid #AE997F80;
                font-size: 16px;
                resize: none;
            }
            textarea::placeholder  {
                color: #AE997F;
            }
        }
    }
    #dropdownMenuLangButton {
        width: 49%;
        padding: 0;
        text-align: left;
        color: #AE997F;    
    }
    .dropdownTog {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0;        
        background-color: #EDE4D4;
        border: none;
        color: #AE997F;
        text-align: left;
        padding: 15px;
        border: 2px solid #AE997F80;
        background-color: #EDE4D4;
        color: #AE997F;
        border-radius: 10px;
        box-shadow: none;
        // font-family: 'Assistant-;
        font-weight: 400;
    }

    .dropdownMenu {
        width: 90%;
        height: 220px;
        overflow: auto;
        background-color: #EDE4D4;       
        cursor: pointer;
        .country {
            // width: 90%;
            margin: 0 auto;
            align-items: center;
            align-self: center;
            color: #AE997F;
            text-indent: 10px;

            p {                          
                border-bottom: 1px solid #AE997F;     
                width: 90%;            
                margin: 0 auto;                                        
                padding-top: 10px;
                padding-bottom: 10px;
            }   

        }
        .country:hover {
            background-color: #E5D8C7;
        }
    }
    .sendMessageBtn {
        font-family: 'Assistant';
        font-weight: 700;
        font-size: 20px;
        width: 55%;
        background-color: #866339;
        border: 1px solid #866339;
        border-radius: 20px;
        padding: 8px 16px;
        color: #CEBAA4;
    }
    .dropdown {
        position: relative;
        cursor: default;
        width: 49%;
        padding: 0;
        text-align: left;
        color: #AE997F;    
      }
      
      .dropdown .arrow {
        border-color: #866339 transparent transparent;
        border-style: solid;
        border-width: 5px 5px 0;
        content: " ";
        display: block;
        height: 0;
        margin-top: 0.3rem;
        position: absolute;
        right: 10px;
        top: 23px;
        width: 0;
      }
      
      .dropdown .arrow.open {
        border-color: transparent transparent #866339;
        border-width: 0 5px 5px;
      }
      
      .dropdown .selected-value input {
        line-height: 1.5;
        font-size: 1rem;
        padding: 15px;
        border: 2px solid #AE997F80;
        background-color: #EDE4D4;
        color: #AE997F;
        border-radius: 10px;
        box-sizing: border-box;
        cursor: default;
        outline: none;
        width: 100%;
        cursor: pointer;
      }
      
      .dropdown .options {
        display: none;
        color: #AE997F;    
        background-color: #EDE4D4;
        border: 1px solid #ccc;
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
        box-sizing: border-box;
        margin-top: -1px;
        max-height: 200px;
        overflow-y: auto;
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 1000;
        -webkit-overflow-scrolling: touch;
      }
      
      .dropdown .options.open {
        display: block;
        border-radius: 10px;
      }
      
      .dropdown .option {
        box-sizing: border-box;
        color: #AE997F;    
        cursor: pointer;
        display: block;
        padding: 8px 10px;
        border: 0.5px solid #AE997F80;
      }
      
      .dropdown .option.selected,
      .dropdown .option:hover {
        background-color: #f2f9fc;
        color: #AE997F;
      }
}