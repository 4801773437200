@import "../../index";

$headline-font-weight: $font-weight-bolder;
$headline-font-size: 18px;
$headline-line-height: 24px;

$headline-font-weight-sm: 400;
$headline-font-size-sm: 16px;
$headline-line-height-sm: 22px;

.footer {
  background-color: #DBCAB8;
  padding-top: 2%;
  overflow: hidden;
  .footerContactIcon {
    width: 85%;
    margin: 0px auto;
  }
  .footerContact {
    display: flex;
    flex-direction: row;
    width: 85%;
    margin: 0 auto;

    .contactAbout {
      width: 50%;
      margin-right: 100px;
      text-align: justify;
      text-justify: inter-word;
      font-size: 13px;
      color: #AE997F;    
    }
    .contactMemberOf, .contactUs {
      width: 30%;
      color: #AE997F;
      margin-right: 50px;
      .dbdBanner {
        align-self: center;
        a {
          padding-left: 0 !important
        }
      }
      h5 {
        font-size: 16px;        
      }
      p {
        width: 100%;
        font-size: 13px;        
      }
      .contactUsList {
        display: flex;
        flex-direction: row;
        img {
          height: 20px;
          margin-right: 10px;
        }
      }
    }
  }
  .footerMoreInfo {
    width: 85%;
    margin: 0px auto;
    color: #AE997F;
    span {
      font-size: 16px;
      font-family: 'Assistant';      
      font-weight: 600;
    }
    a {
      font-size: 13px;
      font-family: 'Assistant';
      font-weight: 400;
      line-height: 30px;
      // margin-bottom: 10px;
    }
    b {
      font-family: 'Assistant'; 
      font-weight: 600;  
      font-size: 13px;   
      color: #866339;
    }
  }

  :global(.headline) {
    font-weight: #{$headline-font-weight};
    font-size: #{$headline-font-size};
    line-height: #{$headline-line-height};

    @include media-breakpoint-down(sm) {
      font-size: #{$headline-font-size-sm};
      line-height: #{$headline-line-height-sm};
    }
  }

  a {
    text-decoration: none;
    color: inherit;

    &:global(.stretched-link) {
      img {
        width: auto;
        height: auto;
      }
    }
  }


  mark {
    color: #{$danger};
    background-color: transparent;
  }

  hr {
    border-top: 2px solid color("white");
  }

  :global(.footer-links) {
    h5 {
      @include text-style(14px, 22px, $font-weight-medium);
      @include media-breakpoint-up(sm) {
        @include text-style(20px, 34px, $font-weight-bolder);
      }
    }
  }

  :global(.my-desc-footer){
    a{
      color: #e14558;
    }
  }
  :global(.footer-service) {
    @include media-breakpoint-down(sm) {
      font-size: 10px;
    }

    img {
      width: auto;
      height: auto;
    }
  }

  :global(.footer-about) {
    a {
      display: block;
    }
  }

  :global(.footer-download) {
    &-icon {
      border-radius: 5px;
      height: 40px;
      width: auto;
    }
  }

  :global(.footer-social) {
    h5 {
      color: #AE997F;
    }
    &-icon {
      height: 40px;
      width: auto;
      margin-right: 0.25rem;
    }
  }

  :global(.footer-contact) {
    &-icon {
      width: 23px;
      height: auto;
      display: inline-block;
      margin-right: 1rem;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        padding: 0.25rem 0;
      }
    }
  }

  :global(.footer-partner) {
    &-icon {
      max-width: 100%;
      height: auto;
      width: auto;
      max-height: 80px;
    }
  }

  :global(.footer-nav) {
    background-color: #866339;
    @include text-style(16px, 24px, $font-weight-normal);

    @include media-breakpoint-down(sm) {
      @include text-style(12px, 18px, $font-weight-normal);
    }

    .footer-nav-container {
      width: 80%;
    }
    :global(.row) {
      min-height: 64px;
    }

    div {
      padding-left: 0px;
      padding-right: 0px;
    }
    a {
      font-size: 14px;
      font-family: 'Assistant';
      font-weight: 400;
      color: #AE997F;
    }
  }
}

.footerMobile {
  background-color: #DBCAB8;
  padding-top: 2%;
  overflow: hidden;
  .footerContactIcon {
    width: 80%;
    margin: 0px auto;
  }
  .footerContact {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
    .contactAbout {
      width: 50%;
      margin-right: 50px;
      text-align: justify;
      text-justify: inter-word;
      font-size: 13px;
      color: #AE997F;    
    }
    .contactMemberOf, .contactUs {
      width: 100%;
      color: hwb(33 50% 32%);     
      .dbdBanner {
        align-self: center;
        a {
          padding-left: 0 !important;
        }
      } 
      h5 {
        font-size: 16px;        
      }
      p {
        width: 100%;
        font-size: 13px;        
      }
      .contactUsList {
        display: flex;
        flex-direction: row;
        img {
          height: 20px;
          margin-right: 10px;
        }
      }
    }
  }
  .footerMoreInfo {
    width: 80%;
    margin: 0px auto;
    color: #AE997F;
    span {
      font-size: 16px;
      font-family: 'Assistant';    
      font-weight: 600;  
    }
    a {
      font-size: 13px;
      font-family: 'Assistant';
      font-weight: 400;
      line-height: 40px;
    }
    b {
      font-family: 'Assistant';   
      font-weight: 600;
      font-size: 13px;   
      color: #866339;
    }
  }

  :global(.headline) {
    font-weight: #{$headline-font-weight};
    font-size: #{$headline-font-size};
    line-height: #{$headline-line-height};

    @include media-breakpoint-down(sm) {
      font-size: #{$headline-font-size-sm};
      line-height: #{$headline-line-height-sm};
    }
  }

  a {
    text-decoration: none;
    color: inherit;

    &:global(.stretched-link) {
      img {
        width: auto;
        height: auto;
      }
    }
  }


  mark {
    color: #{$danger};
    background-color: transparent;
  }

  hr {
    border-top: 2px solid color("white");
  }

  :global(.footer-links) {
    h5 {
      @include text-style(14px, 22px, $font-weight-medium);
      @include media-breakpoint-up(sm) {
        @include text-style(20px, 34px, $font-weight-bolder);
      }
    }
  }

  :global(.my-desc-footer){
    a{
      color: #e14558;
    }
  }

  :global(.logoContainer) {
      padding-top: 30px;
  }
  :global(.footerLongText) {
      color: #AE997F;
      margin-top: 20px;
  }
  :global(.footer-social) {
    h5 {
      color: #AE997F;
    }
    &-icon {
      height: 40px;
      width: auto;
      margin-right: 0.25rem;
    }
  }
  :global(.footer-service) {
    @include media-breakpoint-down(sm) {
      font-size: 10px;
    }

    img {
      width: auto;
      height: auto;
    }
  }

  :global(.footer-about) {
    a {
      display: block;
    }
  }

  :global(.footer-download) {
    &-icon {
      border-radius: 5px;
      height: 40px;
      width: auto;
    }
  }


  :global(.footer-contact) {
    &-icon {
      width: 23px;
      height: auto;
      display: inline-block;
      margin-right: 1rem;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        padding: 0.25rem 0;
      }
    }
  }

  :global(.footer-partner) {
    &-icon {
      max-width: 100%;
      height: auto;
      width: auto;
      max-height: 80px;
    }
  }

  :global(.footer-nav) {
    background-color: #866339;
    @include text-style(16px, 24px, $font-weight-normal);

    @include media-breakpoint-down(sm) {
      @include text-style(12px, 18px, $font-weight-normal);
    }

    .footer-nav-container {
      display: flex;
      width: 80%;
    }
    :global(.row) {
      min-height: 64px;
    }

    div {
      padding-left: 0px;
      padding-right: 0px;
    }
    a {
      font-size: 14px;
      font-family: 'Assistant';
      font-weight: 400;
      color: #AE997F;
    }
  }
}