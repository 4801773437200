@import "../../index";

#navigation {
  font-family: 'Assistant';  
  font-weight: bold;
  font-size: 16px;
  height: 80px;
  padding: 5px 0;
  position: fixed;
  display: block;
  width: 100%;
  z-index: 101;


  @include media-breakpoint-down(sm) {
    display: flex;
    align-items: center;
    height: 60px;
  }

  .navbar-collapse {
    @include media-breakpoint-down(sm) {
      background-color: #ffffff;
      position: fixed;
      width: 100%;
      left: 0;
      right: 0;
      top: 65px;
    }
  }

  &.white {
    background-color: #E5D8C7 !important;
    box-shadow: 0 0 4px #d8d8d8;
  }

  .navbar {
    padding: 0;
  }

  .switch-language {
    margin: 2px 0 0 10px;
  
    @include media-breakpoint-down(sm) {
      display: block !important;
    }
    img {
      margin-bottom: 10px;
    }

    a {
      margin-left: 7px;
      padding-right: 7px;
      text-decoration: none;
      color: #86633980 !important;
      background-color: #E5D8C7;
      @include text-style(12px, 18px, $font-weight-normal, $gray-600);

      &:hover {
       color: #866339 !important;
      }

      &:last-child {
        border-right: none;
      }

      &.active {
       color: #866339 !important;
        // font-weight: #{$font-weight-bolder};
      }
    }
  }

  .logo {
    width: 142px;
    height: auto;
    @include media-breakpoint-down(sm) {
      width: 83px;
      height: auto;
      margin-top: 0;
    }
  }

  .dropdown {
    margin-right: 20px;
  }

  .dropdown:hover>.dropdown-menu {
    display: block;
  }
  
  .dropdown>.dropdown-toggle:active {
      pointer-events: none;
  }

  .dropdown-toggle {
    background-color: #E5D8C7;
    border: 1px solid #E5D8C7;
    color: #86633980;
  }
  .dropdown-toggle:hover {
    color: #866339;
  }
  .dropdown-toggle-active {
    background-color: #E5D8C7;
    border: 1px solid #E5D8C7;
    color: #866339;
  }

  .dropdown-item {
      color: #86633980;
      cursor: pointer;
  }

  .dropdown-icon {
    width: 11px;
    height: 11px;
    margin-left: 9px;
    margin-bottom: 7px;
    margin-top: 5px;
  }

  .dropdown-menu {
    // padding: 0.25rem;
    min-width: 240px;
    max-width: 360px;
    background-color: #E5D8C7;
    border: 1px solid #E5D8C7;
    border-top-left-radius: 0;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .selectedLangToggle {
    background-color: #E5D8C7 !important;
    border: none;
    padding: 0;
    padding-top: 10px;
  }
  .selectedLang {
    min-width: 25px !important;
    padding-top: 5px;
    padding-bottom: 5px;
    // padding: 0px 10px !important;
  }

  .dropdown-item {
    white-space: normal;
    word-break: break-word;
    border-radius: 20px;
    padding: 0.5rem 1rem;
    min-width: 240px;
    max-width: 360px;
    background-color: #E5D8C7;
    &:hover {
      color: #866339;
      background-color: #E5D8C7;
    }

    &:focus {
      background-color: #E5D8C7;
    }

    &:active {
      background-color: #E5D8C7;
    }
  }

  .subMenuContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .subMenuContainer .arrow-container .bold-image {
    display: none;
  }
  
  .subMenuContainer:hover .arrow-container .bold-image {
    display: inline-block;
  }
  
  .subMenuContainer:hover .arrow-container .normal-image {
    display: none;
  }
  
  .normal-image,
  .bold-image {
    display: inline-block;
  }

  .subDropsdown{
    left: 240px;
    border-top-left-radius: 0;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  button.registration {
    font-family: 'Assistant' !important;
    font-weight: 700;
    font-size: 16px;
    height: 53px;
    border-radius: 20px;
    padding: 0 15px;
    background-color: #866339;
    color: #CEBAA4;
    border: none;
    @include text-style(18px, 24px, $font-weight-medium);
  }

  .nav-item {
    white-space: nowrap;
    
    a {
      @include text-style(16px, 24px, $font-weight-medium, $black);
      color: #86633980;
    }
  }

  .about {
    width: 100%;
    position: absolute;
    background-color: #f2f3f5;
    text-align: center;
    display: none;
    box-shadow: 0 1px 1px #dfdfdf;

    a {
      display: inline-block;
      margin: 0 5px;
      color: #676767;
      padding: 0 20px;
      line-height: 50px;
      text-decoration: none;

      &.active {
        position: relative;
        top: 1px;
        color: #{$blue};
        border-bottom: 4px solid #{$blue};
      }
    }
  }

  .menu-toggle {
    height: auto;
    cursor: pointer;
  }

  .mobile-navigation {
    width: 85%;
    padding: 22px 37px;
    background: #E5D8C7;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    overflow-y: scroll;
    transform: translateX(-100%);
    transition: transform 0.25s ease-in;

    &.show {
      transform: translateX(0);
    }

    .navigation-close {
      position: absolute;
      top: 25px;
      right: 35px;
      width: 25px;
      height: auto;
    }

    hr {
      margin: 30px 0;
      background-color: #866339;
    }

    .menu {
      width: 100%;

      h6 {
        @include text-style(16px, 32px, $font-weight-medium);
        color: #866339;
      }

      a {
        width: 100%;
        display: block;
        color: #86633980;
        margin: 15px 0;
        @include text-style(16px, 32px);
      }
      a:hover {
        text-decoration: none;
      }
    }

  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Adjust the alpha value for the desired opacity */
    z-index: 99; /* Higher z-index than the mobile-navigation to appear behind it */
    display: none;
  }

  .mobile-navigation.show + .backdrop {
    display: block;
  }
}
