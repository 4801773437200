#successFully {
    position: fixed;    
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1000;
    .modalContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: #EDE4D4;;
        width: 40%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 30px 40px 30px 40px;
        margin: 0;
        overflow-y: auto;
        border-radius: 20px;
        h2 {
            font-family: "Assistant";
            font-weight: 700;
            font-size: 28px;
            color: #AE997F;
            text-align: center;
        }
        .cross {
            cursor: pointer;
        }
    }
}

#successFullyMobile {
    position: fixed;    
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1000;
    .modalContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: #EDE4D4;;
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 20px;
        margin: 0;
        overflow-y: auto;
        border-radius: 20px;
        h2 {
            font-family: "Assistant";
            font-weight: 700;
            font-size: 28px;
            color: #AE997F;
            text-align: center;
        }
        .cross {
            cursor: pointer;
        }
    }
}